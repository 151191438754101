import { LayoutAuth, LayoutDefault, RouteWrapper } from '@/layouts'

export const publicRoutes = [
  {
    path: '*',
    component: () => import('@/views/error/NotFound.vue'),
  },
  {
    path: '/auth',
    component: LayoutAuth,
    meta: {
      title: 'Login',
    },
    redirect: '/auth/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        meta: {
          title: 'Login',
        },
        component: () => import('@/views/admin/auth/Login.vue'),
      },
    ],
  },

  {
    path: '/404',
    name: '404',
    meta: {
      title: 'Not Found',
    },
    component: () => import('@/views/error/NotFound.vue'),
  },

  {
    path: '/500',
    name: '500',
    meta: {
      title: 'Server Error',
    },
    component: () => import('@/views/error/Error.vue'),
  },
]

export const protectedStuffRoutes = [
  {
    path: '/',
    component: LayoutDefault,
    meta: {
      title: 'home',
      icon: '',
    },
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'AdminDashboard',
        meta: {
          title: 'dashboard',
          icon: 'mdi-equalizer-outline'
        },
        component: () => import('@/views/admin/Dashboard.vue'),
      },
      {
        path: '/customers',
        name: 'AdminCustomerList',
        meta: {
          title: 'Customers',
          icon: 'mdi-account',
        },
        component: () => import('@/views/admin/customers/List.vue'),
      },
      {
        path: '/shop',
        name: 'AdminShopList',
        meta: {
          title: 'Shops',
          icon: 'mdi-store'
        },
        component: RouteWrapper,
        redirect: '/shop/shops',
        children: [
          {
            path: 'shops',
            name: 'AdminShopList',
            meta: {
              title: 'Shops',
              icon: 'mdi-store'
            },
            component: () => import('@/views/admin/shops/List.vue')
          },
          {
            path: 'orders',
            name: 'AdminOrderList',
            meta: {
              title: 'Orders',
              icon: 'mdi-shopping'
            },
            component: () => import('@/views/admin/orders/List.vue')
          },
          {
            path: 'prodcuts',
            name: 'AdminProductList',
            meta: {
              title: 'Products',
              icon: 'mdi-tag',
            },
            component: () => import('@/views/admin/products/List.vue'),
          },
          {
            path: 'collections',
            name: 'AdminCollectionList',
            meta: {
              title: 'Collections',
              icon: 'mdi-format-list-bulleted-type',
            },
            component: () => import('@/views/admin/collections/List.vue'),
          },
          {
            path: 'shopjobs',
            name: 'AdminShopjobList',
            meta: {
              title: 'Shop Jobs',
              icon: 'mdi-cogs'
            },
            component: () => import('@/views/admin/shopjobs/List.vue')
          }
        ]
      }
    ]
  }
];

export const protectedCustomerRoutes = [
  {
    path: '/',
    component: LayoutDefault,
    meta: {
      title: 'home',
      icon: '',
    },
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          title: 'dashboard',
          icon: 'mdi-equalizer-outline',
        },
        component: () => import('@/views/admin/Dashboard.vue'),
      }
    ]
  }
]
