import dayjs from "dayjs"

export function toggleFullScreen() {
    let doc = window.document
    let docEl = doc.documentElement
  
    let requestFullScreen =
      docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen
    let cancelFullScreen =
      doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen
  
    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl)
    } else {
      cancelFullScreen.call(doc)
    }
  }
  

// 过滤取值
export function filterValue(data, value, { toNumber = false, key = 'value', label = 'label', directKey = '' } = {}) {
  const val = toNumber ? Number.parseInt(value) : value
  const obj = data.find(v => v[key] === val) || {}
  if (directKey) return obj[directKey]
  return obj[label] || value
}

// 时间格式化
export function dateFormat(date, dateFormat = 'YYYY-MM-DD HH:mm:ss') {
  if (!date) {
    return '';
  }
  return dayjs(date).format(dateFormat);
}

// 时间格式化
export function priceFormat(val) {
  return (parseInt(val) / 100).toFixed(2)
}

// 获取对象值 eg: getValue(shop, 'image.id')
export function getValue(srcObj, pathStr, defaultVal = '') {
  let val = {}
  if (srcObj && typeof pathStr === 'string') {
    val = srcObj
    var paths = pathStr.split('.')
    var key = null
    for (var v in paths) {
      key = paths[v]
      if (key && val[key] !== undefined && val[key] !== null) {
        val = val[key]
      } else {
        val = null
        break
      }
    }
  }
  if (val === null || val === undefined) {
    val = defaultVal
  }
  return val
}

export function openWindowWithNoRefer(link){ 
  window.open('javascript:window.name;', '<script>location.replace("' + link + '")<\/script>');
}

/**
 * @param {string} path
 * @returns {Boolean}
 */
 export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}
