// 店铺相关常量
export const shop = {
  status: [
    { label: 'connected', value: 'connected', color: 'green' },
    { label: 'disconnected', value: 'disconnected', color: 'red' },
  ]
}

export const product = {
  status: [
    { label: 'Draft', value: 1, color: 'gary' },
    { label: 'Archived ', value: 2, color: 'orange' },
    { label: 'Active', value: 3, color: 'rgb(174 233 209)' },
    { label: 'Draft', value: 'draft', color: 'gary' },
    { label: 'Archived ', value: 'archived', color: 'orange' },
    { label: 'Active', value: 'active', color: 'rgb(174 233 209)' }
  ]
}

export const order = {
  financial_status: [
    { label: "pending", value: "pending", color: "gray"},
    { label: "paid", value: "paid", color: "primary"},
    { label: "partially_paid", value: "partially_paid", color: "primary"},
    { label: "refunded", value: "refunded", color: "red"},
    { label: "voided", value: "voided", color: "red"},
    { label: "partially_refunded", value: "partially_refunded", color: "red"},
    { label: "unpaid", value: "unpaid", color: "gray"}
  ],
  fulfillment_status: [
    { label: "shipped", value: "shipped", color: "primary"},
    { label: "partial", value: "partial", color: "primary"},
    { label: "unshipped", value: "unshipped", color: "green"},
    { label: "unfulfilled", value: "unfulfilled", color: "#ffea8a"}
  ]
}