import {
  shop,
  product,
  order
} from '@/constants'

import { filterValue } from '@/utils'


// 店铺过滤器
export const filterShop = (value, labelKey, directKey = '') => {
  const res = shop[labelKey] || []
  return filterValue(res, value, { directKey })
}

// 商品过滤器
export const filterProduct = (value, labelKey, directKey = '') => {
  const res = product[labelKey] || []
  return filterValue(res, value, { directKey })
}

// 订单过滤器
export const filterOrder = (value, labelKey, directKey = '') => {
  const res = order[labelKey] || []
  return filterValue(res, value, { directKey })
}

// 商品订单
export const filterOrderSummary = (order) => {
  let html = `<div>Currency: ${order.currency}</div>`;
  const total_save = parseFloat(order.total_price) - parseFloat(order.subtotal_price) + parseFloat(order.total_tax) - parseFloat(order.total_discounts);
  html += `<div>Subtotal Price: ${parseFloat(order.subtotal_price).toFixed(2)}</div>`;
  html += `<div title="Discount">D: ${parseFloat(order.total_discounts).toFixed(2)}</div>`;
  html += `<div title="Tax">T: ${parseFloat(order.total_tax).toFixed(2)}</div>`;
  html += `<div title="Save">S: ${total_save.toFixed(2)}</div>`;
  html += `<div>Total: ${parseFloat(order.total_price).toFixed(2)}</div>`;
  return html;
}
