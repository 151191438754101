import { defineStore } from 'pinia'

export const useStoreApp = defineStore('app', {
  state: () => ({
    mode: 'light',
    themeColor: '#2196f3',
    theme: 'blue',
    snackbar: {
      show: false,
      text: '',
      color: '',
    },
    locale: 'en',
    translation: null,
    notificatons: [
      {
        title: 'New user registered',
        color: 'light-green',
        icon: 'mdi-account-circle',
        timeLabel: 'Just now',
      },
      {
        title: 'New order received',
        color: 'light-blue',
        icon: 'mdi-cart-plus',
        timeLabel: '2 min ago',
      },
      {
        title: 'New payment made',
        color: 'cyan',
        icon: 'mdi-receipt',
        timeLabel: '24 min ago',
      },
      {
        title: 'New message from Michael',
        color: 'red',
        icon: 'mdi-email',
        timeLabel: '1 hour ago',
      },
    ],
    scrollUpdate: false
  }),
  actions: {
    setTranslation(translation) {
      this.translation = translation
    },
    setTheme(theme) {
      this.theme = theme
    },
    setThemeColor(themeColor) {
      this.themeColor = themeColor
    },
    clearNotifications() {
      this.notificatons = []
    },
    updateScroll(status) {
      this.scrollUpdate = status
    }
  },
  getters: {

  }
})