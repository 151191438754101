
import { defineStore } from 'pinia'
import { login, logout, getCurUser } from '@/api/auth'
import { setToken, removeToken, cloneRoute } from '@/utils/auth'

export const useStoreAuth = defineStore('auth', {
    state: () => ({
        user_id: 0,
        username: '',
        avatar: '',
        token: null,
        route: cloneRoute(),
        addedRoutes: []
    }),
    actions: {
        setToken(token) {
          this.token = token
        },
        removeToken() {
            this.token = null
            removeToken()
        },
        setRoute(to, from) {
            this.route = cloneRoute(to, from)
        },
        login(formData) {
          return new Promise((resolve, reject) => {
            login(formData).then(response => {
              const { data } = response
              setToken(data.token)
              this.token = data.token
              resolve()
            }).catch(error => {
              reject(error)
            })
          })
        },
        async logout() {
            const { code } = await logout()
            if (code === 200) {
                this.removeToken()
                this.username = ''
                this.avatar = ''
                this.addedRoutes = []
                return true
            }
            return false
        },
        async getCurUser() {
          const { code, data } = await getCurUser()
          if (code === 200) {
            this.user_id = data.user_id
            this.username = data.username
            this.avatar = data.avatar
            return true
          }
          return false
        }
    },
    getters: {
        
    }
})

