import logo from '@/assets/logo.png'

export default {
  /**
   * @type {string}
   * @description App Name
   */
  name: 'LYNX',
  /**
   * @type {string}
   * @description App Name
   */
  logo: logo

}
