<template>
  <v-app-bar color="white" app>
    <v-app-bar-nav-icon class="d-lg-none" @click="handleDrawerToggle" />
    <img :src="appSettings.logo" height="36" :alt="appSettings.name" />
      <v-toolbar-title>
        <span class="hidden-sm-and-down ma-2">{{ appSettings.name }}</span>
      </v-toolbar-title>
    <v-spacer />
    <v-toolbar-items>
      <v-btn class="d-none" icon @click="handleFullScreen()">
        <v-icon>mdi-fullscreen</v-icon>
      </v-btn>
      <v-menu v-if="showNotificaton" offset-y origin="center center" class="elelvation-1" transition="scale-transition">
        <template #activator="{ on }">
          <v-btn slot="activator" icon text v-on="on">
            <v-badge color="red" overlap>
              <span slot="badge">{{ notificatons.length }}</span>
              <v-icon medium>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <notification-list v-show="notificatons.length > 0" :items="notificatons" />
      </v-menu>
      <!-- locale -->
      <v-menu offset-y origin="center center" transition="scale-transition">
        <template #activator="{ on }">
          <v-btn :depressed="true" slot="activator" icon large text v-on="on">
            <c-avatar :size="36" :username="username" :src="avatar" status="online" />
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-item
            v-for="(item, index) in profileMenus"
            :key="index"
            :to="!item.href ? { name: item.name } : null"
            :href="item.href"
            :disabled="item.disabled"
            :target="item.target"
            rel="noopener"
            @click="item.click"
          >
            <v-list-item-action v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
    <v-toolbar v-if="extended" slot="extension" tag="div" dense color="white" light>
      <v-icon>mdi-home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-3" />
      <v-spacer></v-spacer>
      <v-btn icon small color="black">
        <v-icon @click="handleGoBack" v-text="'mdi-arrow-left'" />
      </v-btn>
    </v-toolbar>
  </v-app-bar>
</template>
<script>
import NotificationList from '@/components/list/NotificationList'
import CAvatar from '@/components/avatar/CAvatar'
import { toggleFullScreen } from '@/utils/index'
import { mapState, mapActions } from 'pinia'
import { useStoreAuth } from '@/store/auth'
import { useStoreApp } from '@/store/app'
import appSettings from '@/settings'

export default {
  name: 'AppToolbar',
  components: {
    NotificationList,
    CAvatar,
  },
  props: {
    extended: Boolean,
  },
  data() {
    return {
      appSettings,
      showNotificaton: false,
      profileMenus: [
        // {
        //   icon: 'mdi-account',
        //   href: '#',
        //   title: 'Profile',
        //   click: this.handleProfile,
        // },
        // {
        //   icon: 'mdi-cog',
        //   href: '#',
        //   title: 'Settings',
        //   click: this.handleSetting,
        // },
        {
          icon: 'mdi-power',
          href: '#',
          title: 'Logout',
          click: this.handleLogut,
        },
      ],
    }
  },
  computed: {
    ...mapState(useStoreApp, ['notificatons']),
    ...mapState(useStoreAuth, ['avatar', 'username']),
    breadcrumbs() {
      const { matched } = this.$route
      return matched.map((route, index) => {
        const to = index === matched.length - 1 ? this.$route.path : route.path || route.redirect
        const text = this.$t(route.meta.title)
        return {
          text: text,
          to: to,
          exact: true,
          disabled: false,
        }
      })
    },
  },
  created() {},
  methods: {
    ...mapActions(useStoreAuth, ['logout']),
    handleDrawerToggle() {
      this.$emit('side-icon-click')
    },
    handleFullScreen() {
      toggleFullScreen()
    },
    async handleLogut() {
      this.$dialog.confirm(
        'Are you sure to logout?',
        'Logout',
        async () => {
          const success = await this.logout()
          if (success) {
            this.$router.push('/auth/login')
          }
        },
        () => {},
        {persistent: true}
      );
    },

    handleSetting() {},
    handleProfile() {},
    handleGoBack() {
      this.$router.go(-1)
    },
  },
}
</script>
