<template>
  <v-navigation-drawer
    v-model="showDrawer"
    app
    :hide-overlay="true"
    class="app-drawer"
    :mini-variant.sync="mini"
    mini-variant-width="64"
    :width="drawerWidth"
  >
    <nav-list :items="computeMenu" :mini="mini" />
  </v-navigation-drawer>
</template>
<script>
import { mapState } from 'pinia'
import { useStoreAuth } from '@/store/auth'
import NavList from '@/components/nav/NavList'
import appSettings from '@/settings'

export default {
  name: 'AppDrawer',
  components: { NavList },
  data() {
    return {
      appSettings,
      mini: false,
      showDrawer: true,
      drawerWidth: 256,
      scrollSettings: {
        maxScrollbarLength: 160,
      },
      bs: null
    }
  },

  computed: {
    ...mapState(useStoreAuth, ['addedRoutes']),
    computeMenu() {
      return this.filterRouteItem(this.addedRoutes[0].children)
    },
    computeHeight() {
      return {
        height: this.height || '',
      }
    },
  },

  methods: {
    filterRouteItem(routes) {
      return routes
        .filter((item) => item.meta.hidden !== true)
        .map((item) => {
          return {
            title: this.$t(item.meta.title),
            icon: item.meta.icon,
            path: item.path,
            isNew: item.meta.isNew || false,
            children: item.children ? this.filterRouteItem(item.children) : [],
          }
        })
    },
    handleDrawerCollapse() {
      this.mini = !this.mini
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer
    },
  },
}
</script>

<style lang="sass" scoped>
.app-drawer
  top: 64px !important
  max-height: calc(100% - 64px) !important
  &__srollbar
    max-height:  calc(100vh - 64px - 36px - 44px)
  &__inner
    height: calc(100vh - 64px - 36px - 44px)
  ::v-deep
    .v-navigation-drawer__border
      width: 0
    .v-list-group__items > .v-list-item
      padding-left: 32px
    .v-list-item__icon
      margin-right: 16px
</style>
