<template>
  <div class="admin">
    <app-toolbar class="admin_toolbar" @side-icon-click="handleDrawerVisiable" />
    <app-drawer ref="drawer" class="admin_drawer" @drawer:collapsed="mini = !mini" />
    <v-main class="main_view grey lighten-3">
      <!-- Page Wrapper -->
      <v-card class="rounded-lg router-view__wapper">
        <router-view />
      </v-card>

      <!-- App Footer -->
      <v-footer v-if="showFooter" height="auto" class="pa-3 app--footer">
        <span>LYNX &copy; {{ new Date().getFullYear() }}</span>
        <v-spacer />
        <span class="caption mr-1">Make With Love</span>
        <v-icon color="pink" small>mdi-heart</v-icon>
      </v-footer>
    </v-main>
    <!-- Go to top -->
    <app-fab />
  </div>
</template>

<script>
import AppDrawer from '@/components/AppDrawer'
import AppToolbar from '@/components/AppToolbar'
import AppFab from '@/components/AppFab'

export default {
  name: 'LayoutDefault',
  components: {
    AppDrawer,
    AppToolbar,
    AppFab
  },
  data() {
    return {
      mini: false,
      showDrawer: true,
      showFooter: false
    }
  },
  methods: {
    handleDrawerVisiable() {
      this.$refs.drawer.toggleDrawer()
    },
  },
}
</script>

<style lang="sass" scoped>
.main_view
  height: 100vh
  overflow-y: scroll
  padding-top: 0 !important
  ::v-deep
    .v-main__wrap
      padding: 10px
.admin_toolbar
  left: 0 !important
  width: 256px
  box-shadow: none !important
@media screen and (max-width: 1264px)
  .admin_toolbar
    width: auto
  .main_view
    padding-top: inherit !important
    ::v-deep
      .v-main__wrap
        padding-top: 75px
</style>
