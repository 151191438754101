import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from './i18n';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        t: (key, ...params) => i18n.t(key, params),
        current: 'en',
    },
    theme: {
      themes: {
        light: {
            primary: '#008060',
            secondary: '#cccccc',
            error: '#d82c0d',
            warning: '#ffc453',
            success: '#008060'
        }
      }
    }
})
  