import Vue from 'vue'
import * as core from './modules/core' // 核心模块
import * as shop from './modules/shop' // 核心模块

const Filters = {
  ...core,
  ...shop
  // more...
}

for (const k in Filters) {
  if (Object.prototype.hasOwnProperty.call(Filters, k)) {
    Vue.filter(k, Filters[k])
  }
}
