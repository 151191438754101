import { dateFormat } from '@/utils'

export const formatProductPrice = (product, key) => {
  const max_key = 'max_' + key
  const min_key = 'min_' + key
  let max_value = product[max_key]
  let min_value = product[min_key]
  if (max_value === 0 && min_value == 0) {
    return '-'
  }
  max_value = (max_value / 100).toFixed(2)
  min_value = (min_value / 100).toFixed(2)
  if (max_value === min_value) {
    return `$ ${max_value}`
  }
  return `$ ${min_value} - $ ${max_value}`
}

export const localDatetime = (date) => {
  return dateFormat(date)
}