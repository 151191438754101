import Vue from 'vue'
import router from './router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import pinia from './plugins/pinia'
import i18n from './plugins/i18n'
import './theme/default.sass'

import '@/filters'

import SnackbarComponent from '@/components/snackbar'
import DialogComponent from '@/components/dialog'

Vue.config.productionTip = false

Vue.use(SnackbarComponent, {
  vuetify,
  timeout: 3000,
});

Vue.use(DialogComponent, {
  vuetify,
});

var ctx = new Vue({
  router,
  vuetify,
  pinia,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default ctx