import Vue from 'vue'
import Router from 'vue-router'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import { getToken } from '@/utils/auth'
import { useStoreAuth } from '@/store/auth'

import { publicRoutes, protectedStuffRoutes } from './config'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: publicRoutes,
})

// no redirect whiteListNames
const whiteListNames = ['login'] 

// router gards
router.beforeEach(async(to, from, next) => {
  const store = useStoreAuth()

  NProgress.start()

  document.title = to.meta.title
  const token = getToken()

  const hasRoutes = store.addedRoutes && store.addedRoutes.length > 0
  if (token) {
    if (to.name === 'login') {
      const { redirect } = to.query
      const path = redirect || '/'

      // if is logged in, redirect to the home page or the redirect path page.
      next({ path })

      NProgress.done()
    } else {
      if (hasRoutes) {
        next()
      } else {
        try {
          await store.getCurUser()
          store.addedRoutes = protectedStuffRoutes
          router.addRoutes(store.addedRoutes)
          next({ ...to, replace: true })
        } catch (error) {
          await store.logout()
          next(`/auth/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    if (whiteListNames.indexOf(to.name) !== -1) {
      // in the free login whiteListNames, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next({ name: 'login', query: { redirect: to.path } })
      NProgress.done()
    }
  }

  //auth route is authenticated
})

router.afterEach(function (to, from) {
  NProgress.done()
  const authStore = useStoreAuth()
  authStore.setRoute(to, from)
})

export default router
