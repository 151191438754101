import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import { createPinia } from 'pinia'
import { useStoreApp } from '@/store/app'

const pinia = createPinia();
const store = useStoreApp(pinia);

import zhHans from '@/locale/zh-Hans.json'
import en from '@/locale/en.json'
const translation = {
  zh: zhHans,
  en: en,
}
const i18n = new VueI18n({
  locale: store.locale, // set locale
  fallbackLocale: 'en',
  messages: translation, // set locale messages
})

store.translation = translation;

export default i18n
