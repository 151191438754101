// 写 cookies
export const setCookie = function setCookie(name, value) {
  // eslint-disable-next-line no-undef
  document.cookie = name + '=' + escape(value)
}

// 读 cookies
export const getCookie = function(name) {
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  const arr = document.cookie.match(reg)
  return arr ? unescape(arr[2]) : null
}

// 删 cookies
export const delCookie = function(name) {
  var exp = new Date()
  exp.setTime(exp.getTime() - 1)
  var cval = getCookie(name)
  if (cval != null) {
    document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString()
  }
}

// 获取Token
export const getToken = function() {
  if (typeof window === 'undefined') {
    return ''
  }
  if (window.sessionStorage && window.sessionStorage.Bearer) {
    return window.sessionStorage.Bearer
  } else if (window.localStorage && window.localStorage.Bearer) {
    return window.localStorage.Bearer
  } else if (window.document.cookie) {
    return getCookie('Bearer')
  }
}

// 设置Token
export const setToken = function(token, rememberTime) {
  if (window.localStorage) {
    window.localStorage.Bearer = token
  }

  if (
    window.document.cookie && !window.sessionStorage && !window.localStorage
  ) {
    if (rememberTime) {
      setCookie('Bearer', token, rememberTime)
    } else {
      setCookie('Bearer', token)
    }
  }
}

// 删除Token
export const removeToken = function() {
  if (window.sessionStorage && window.sessionStorage.Bearer) {
    window.sessionStorage.removeItem('Bearer')
  }

  if (window.localStorage && window.localStorage.Bearer) {
    window.localStorage.removeItem('Bearer')
  }

  if (window.document.cookie) {
    delCookie('Bearer')
  }
}

export const cloneRoute = function(to = {}, from) {
  var clone = {
    name: to.name ?? '',
    path: to.path ?? '',
    hash: to.hash ?? '',
    query: to.query ?? {},
    params: to.params ?? {},
    fullPath: to.fullPath ?? '',
    meta: to.meta ?? {}
  }
  if (from) {
    clone.from = cloneRoute(from)
  }
  return clone
}