import { api_get, api_post} from '@/utils/request'

export function getCurUser() {
    return api_get({
      url: `/accounts/current`
    })
  }

export function login(data) {
  return api_post({
    url: `/auth/login`,
    data
  })
}

export function logout() {
    return api_post({
      url: '/auth/logout'
    })
  }