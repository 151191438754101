import axios from 'axios'
import qs from 'qs'
import { useStoreAuth } from '@/store/auth'
import { getToken } from '@/utils/auth'
import { getValue } from '@/utils'
import ctx from '@/main'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})


// request interceptor
service.interceptors.request.use(
  config => {

    const authStore = useStoreAuth()

    const token = authStore.token || getToken()
    // do something before request is sent
    if (token) {
      // let each request carry token
      config.headers.Authorization = `Bearer ${token}`
    }

    return config

  },
  error => {
    return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use(
 
  response => {
    const token = response.headers.authorization

    const authStore = useStoreAuth()

    if (token) {
      authStore.setToken(token)
    }
 
    const data = response.data
    const isBlob = response.request['responseType'] === 'blob'
    
    // if the responseType is a blob.
    if (isBlob) {
      return response
    // if the custom code is not 20000, it is judged as an error.
    } else if ( !([200, 201].includes(data.code)) ) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (data.code === 401) {

        const currentPath = authStore.route.path

        if (currentPath === '/login') return

        ctx.$dialog.confirm(
          'Token has expired, do you want to relogin?',
          'Relogin',
          () => {
            authStore.removeToken()
            location.reload()
          },
          () => {},
          {persistent: true}
        );

      } else {
        ctx.$toast.error(data.message || 'Error')
      }

      return Promise.reject(new Error(data.message || 'Error'))

    } else {
      return data
    }
  },
  err => {
    const status = getValue(err, 'response.status', 400)
    const message = getValue(err, 'response.data.message', 'Request error')

    switch (status) {
      case 400:
         err.message = 'Request error'
         break
       case 401:
         err.message = 'Unauthorized, please log in'
         break
       case 403:
         err.message = 'Access Denied'
         break
       case 404:
         err.message = `Error requesting address: ${err.response.config.url}`
         break
       case 408:
         err.message = 'Request timed out'
         break
       case 500:
         err.message = 'Server Internal Error'
         break
       case 501:
         err.message = 'Service not implemented'
         break
       case 502:
         err.message = 'Gateway error'
         break
       case 503:
         err.message = 'Service unavailable'
         break
       case 504:
         err.message = 'Gateway timed out'
         break
       case 505:
         err.message = 'HTTP version not supported'
         break
       default:
    }
    ctx.$toast.error((err.message + ' : ' + message) || 'Error')
    return Promise.reject(err)
})

const defaultSettings = { 
  dataType: 'json',   // 请求的数据格式，默认 formData , 可选 formData2, json
  mock: false          // 是否为 mock 模式
}

export const request = function (
  option,
  { 
    dataType = 'json',   // 请求的数据格式，默认 formData , 可选 formData2, json
    mock= false          // 是否为 mock 模式
  } = defaultSettings
){

  let defaultHeaders = {}

  if ( dataType === 'formData' ){
    defaultHeaders = {
      'Content-Type': 'application/x-www-form-urlencoded',  // 发送 formData 数据格式
    }
    
    option.data = qs.stringify(option.data)

  } else if ( dataType === 'formData2' ) {
    defaultHeaders = {
      'Content-Type': 'multipart/form-data',   // 含文件
    }
  }
  
  option.headers = Object.assign( defaultHeaders, option.headers || {} )

  if (mock && process.env.VUE_APP_MOCK_API) { 
    option.url = `${process.env.VUE_APP_MOCK_API}${option.url}` 
  }

  return new Promise((resolve, reject) => {
    service(option)
    .then(resolve)
    .catch(reject)
    .finally()
  })
}

const action = function(method ,options, settings = defaultSettings) {
  options.method = method;
  return request(options, settings)
}

export const api_get = function(options, settings = defaultSettings) {
  return action('get', options, settings)
}

export const api_post = function(options, settings = defaultSettings) {
  return action('post', options, settings)
}

export const api_put = function(options, settings = defaultSettings) {
  return action('put', options, settings)
}

export const api_delete = function(options, settings = defaultSettings) {
  return action('delete', options, settings)
}